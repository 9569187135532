@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed|Monda:700&display=swap');

html body .app.flex-row.align-items-center {
  height: 100vh;
}
body{
  font-family: "IBM Plex Sans Condensed", sans-serif;

  font-weight: 500;
}

.mapboxgl-ctrl-attrib-inner{
 display: none !important;
}

.mapboxgl-ctrl-logo{
  display:none !important;
}

.sidebar {
  background: #3a4149;
  border-right: 1px solid #23282c;
}

.sidebar .nav-link .nav-icon {
  display: inline-block;
  width: 1.09375rem;
  margin: 0 0.5rem 0 0;
  font-size: 0.875rem;
  color: #b8babc7d;
  text-align: center;
}

.sidebar .nav-link.active .nav-icon {
  color: #ffffff;
}

.sidebar .nav-link:hover {
  color: #fff;
  background: #ff4645;
}

.sidebar .nav-link.active {
  background: #ef3937;
}

a {
  color: #ff181f;
  text-decoration: none;
  background-color: transparent;
}

@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
    background: #d82020;
  }
}

.scrollbar {
  margin-left: 30px;
  float: left;
  height: 200px;
  width: 65px;
  background: #F5F5F5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

.custom-control {
  min-height: 1.400rem;
  font-size: 13px;
  font-family: 'IBM Plex Sans Condensed', sans-serif;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #f36767;
  border-color: #ffa6a5;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ef3937;
  border-color: #f7a3a2;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f7a3a2;
  background-color: #ef3937;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
  background-color: #333;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #575757;
  border-radius: 10px;
}

/* the new scrollbar will have a flat appearance with the set background color */

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

/* this will style the thumb, ignoring the track */

.breadcrumb {
  text-transform: uppercase;
  line-height: 15px;
  border-bottom: 1px solid #23282c;
  background-color: #3a4149;

}

.breadcrumb-item.active {
  color: #fff;
}

.logo-Risk {
  color: white;
  font-weight: 600;
  font-size: 29px;
}


.contenido-logo-resk {
  align-content: center;
}

.app-header {
  background-color: #3a4149;
  border-bottom: 1px solid #23282c;
}

// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #root {
    display: flex;
    flex-direction: column;
  }
}

#tooltip:empty {
  display: none;
}

#tooltip {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  position: absolute;
  padding: 4px;
  margin: 8px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 300px;
  font-size: 10px;
  z-index: 9;
  pointer-events: none;
  text-align: center;
}

#map {
  width: 100vw;
  height: 100vh;
}

.control-panel {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 300px;
  opacity:0.5;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 10px;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  text-transform: uppercase;
}

label {
  display: inline-block;
  width: 150px;
}


.input.disabled {
  opacity: .5;
  cursor: not-allowed;
}

.botonExecute {
  padding-top: 10px;
}

.dangerColor {
  background-color: #d63031 !important;

}

#styleContainer {
  max-width: 810px;
}
#styleBtnLogin{
  min-width: 419px;
  color: white;
  background: #ef3937;
}
#styleBtn{
  min-width: 419px;
  color: white;
  background: #ef3937;
  border: none;
}

#styleBtnOnlyLogin{
  width: 100%;;
  color: white;
  background: #ef3937;
}

#headerStyle-login{
  color:white;
  padding: 20px;
}
#headerStyle-login > a {
  color:white;
  font-size: 15px;
}

.control-panel-dash {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 300px;
  opacity:0.9;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 10px;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  text-transform: uppercase;
}
.control-panel-dash-top5{
    position: absolute;
    top: 0;
    right: 0;
    width: 166px;
    font-weight: bold;
    opacity:0.8;
    background: rgba(255, 255, 255, 0.77);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    padding: 6px 6px;
    margin: 10px;
    font-size: 10px;
    line-height: 2;
    color: #272728;
    outline: none;
    text-transform: uppercase;
}
.control-panel-dash-mapa-principal{
    position: absolute;
    top: 0;
    left: 0;
    width: 110px;
    max-width: 115px;
    opacity:0.8;
    background: rgba(255, 255, 255, 0.77);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    padding: 6px 6px;
    margin: 10px;
    font-size: 10px;
    line-height: 2;
    color: #3c3c40;
    outline: none;
    text-transform: uppercase;
};

.container-grafco-mapa-principal {
    position: absolute;
    top: 0;
    right: 0;
    width: 555px;
    opacity: 0.9;
    background: #333;
    box-shadow: 0 2px 4px rgba(104, 104, 104, 0.3);
    //padding: 10px 5px;
    margin: 0px;
    font-size: 10px;
    line-height: 2;
    color: #6b6b76;
    outline: none;
    text-transform: uppercase;
}

.container-grafco-mapa-principal1 {
    position: absolute;
    top: 0;
    right: 0;
    //padding: 12px 24px;
    margin: 20px;

}
.control-panel-dash-riego {
  position: absolute;
  top: 0;
  right: 0;
  width: 110px;
  max-width: 115px;
  opacity:0.8;
  background: rgba(255, 255, 255, 0.77);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 6px 6px;
  margin: 10px;
  font-size: 10px;
  line-height: 2;
  color: #3c3c40;
  outline: none;
  text-transform: uppercase;
}
.label-dash {
  display: inline-block;
  width: 100px;
  margin-bottom: 1px;
}

.witheCol {
  background-color: #40739e;
}

.sizeTable{
  font-size: 13px;
}

#pagin{
  float: right;
  margin-left: 15px;
}
#pagin > .pagination > li > a{
  color:#fff;
  padding: 8px;
  font-weight: 400;
  border: 1px solid #575757;
  border-radius: 5px;
  background-color: #3e4449;
}
#pagin > .pagination>.active>a{
  z-index: 3;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  background-color: #ef3937;
  border-color: #ef3937;
  cursor: default;
}

.leaflet-container {
  height: 500px;
  width: 100%;
}

.leaflet-container{
  margin-top: 50px !important;
  margin: 0 auto;
}

.picky__dropdown{
  z-index: 1000000000000000 !important;
}

.sobrePoner{
  z-index: 1000000000000000 !important;
}

.react-datepicker-popper{
  z-index: 1000000000000000 !important;
}

.spanCenter {
  display: block;
  text-align: center;
}

.minWidth150{
  min-width: 150px;
}


//card
.ColorCard {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #333;
  background-clip: border-box;
  border: 1px solid #212121;
  border-radius: 0.25rem;
}

.buttonDefault {
  width: 100%;
  background-color: #ef3937;
  border: #ef3937;
  color: white;
}

.buttonDefault:hover {
  width: 100%;
  background-color: #d42422;
  color: white;
}

.buttonDefault:active {
  width: 100%;
  background-color: #d42422 !important;
  color: white !important;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
#contenedor-grafico.mapa-principal {
    padding: 10px;
}
/* Smartphones (portrait & landscape) */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {

}
@media only screen and (max-width: 320px) {
    #div-riegoPolvo {
        display: none;
    }
    #div-riegoPolvoVer {
        display: initial;
        font-size: 11px;
    }
    .container-grafco-mapa-principal1 {
        width: 300px;
        margin: 5px;
        padding: 0px 0px;
    }

}

//PANTALLA PC
@media screen and (max-width: 640px) {
    #riegoPolvoVerPc {
        display: none;
    }
    #riegoPolvoVermovil {
        font-size: 11px;
        display: initial;
    }
    .container-grafco-mapa-principal1 {
        width: 300px;
        margin: 5px;
        padding: 0px 0px;
    }

    .container-grafco-mapa-principal {
        width: 300px;
    }


}

@media screen and (min-width: 640px) {
    #riegoPolvoVerPc {
        display: initial;
    }
    #riegoPolvoVermovil {
        display: none;
    }
}

/* Smartphones (landscape) */
@media only screen
and (min-width : 321px) {

}

/* Smartphones (portrait) */
@media only screen and (max-width : 320px) {
  #div-riegoPolvo{
    display: none;
  }
  #div-riegoPolvoVer{
    display: initial;
    font-size: 11px;
  }
}
//PANTALLA PC
@media screen and (max-width: 640px) {
  #riegoPolvoVerPc{
    display:none;
  }
  #riegoPolvoVermovil{
    font-size:11px;
    display: initial;
  }
}
@media screen and (min-width: 640px) {
  #riegoPolvoVerPc{
    display:initial;
  }
  #riegoPolvoVermovil{
    display: none;
  }
}
@media (min-width: 1200px) {
    .modal-xl {
        max-width: 95%;
    }
    .react-datepicker {
        left: -70px;
    }
    .react-datepicker__triangle {
        left: 141px !important;
    }
}
